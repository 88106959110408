@import "@evrone-erp/evrone-erp-ui/lib/css/index";
@import "@evrone-erp/evrone-erp-ui/lib/css/style";

.nav {
  background-image: none;
  z-index: 100;
}

.nav-content {
  flex-grow: 2;
}

.priority {
  background-color: rgba(var(--bs-secondary-rgb), 0.4);
  padding: 4px 6px;
  border-radius: 5px;
}

.user-profile {
  margin: 0;
  display: inline-block;
  z-index: 1;
}

.user-profile-thumb {
  display: inline-block;
  vertical-align: top;
  width: 24px;
  min-width: 24px;
  flex-basis: 24px;
  height: 24px;
  margin-right: 5px;
  border-radius: 50%;
  overflow: hidden;
}

.user-profile-img {
  object-fit: cover;
  display: block;
}
